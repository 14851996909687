<template>
  <div>
    <home-view />
  </div>
</template>

<script>
import HomeView from '@/views/HomeView.vue';

export default {
  components: { HomeView },
  setup() {
  },
};
</script>

<style lang="scss">
/* @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@400;700&display=swap');

* {
  font-family: 'Chivo', sans-serif;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
