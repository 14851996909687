<template>
  <div class="wrapper">
    <div class="main-display">
      <div class="logo">
        <img src="@/assets/images/baishouse-logo.png" alt="logo" />
      </div>
      <div class="heading">
        Branding - Applications - Information Systems
      </div>
      <div class="sub-heading">
        full website coming soon
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: block;
}
.main-display {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100vw;
  height: 100px;

  img {
    width: auto;
    height: 50px;
  }
}
.heading {
  width: 100vw;
  height: auto;
  text-transform: uppercase;
}

.sub-heading {
  width: 100vw;
  padding-top: 30px;
  height: auto;
  text-transform: lowercase;
  color: #09BC8A;
  font-size: 13px;
}
</style>
